export const getLocalStorageBoolean = (key, { defaultValue } = {}) => getLocalStorage(key, { defaultValue }) === 'true';

export const getLocalStorage = (key, { defaultValue } = {}) => {
  try {
    return localStorage.getItem(key) || defaultValue;
  } catch (e) {
    console.log('Local storage not supported', e);
    return null;
  }
};

export const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.log('Local storage not supported', e);
  }
};

export const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log('Local storage not supported', e);
  }
};
