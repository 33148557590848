import React, { useState } from 'react';
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';
import PlayRateMarker from './PlayRateMarker';

export const playbackRates = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

const PlayRateSlider = ({ selectedValue, handleOnSliderChange }) => {
  const { t } = useTranslation('VideoPlayer');

  const sliderMarks = playbackRates.reduce((memo, rate) => {
    memo[rate] = PlayRateMarker({
      value: `${rate}x`,
      active: selectedValue === rate,
    });
    return memo;
  }, {});

  return (
    <div>
      <div className="playrate-slider">
        <span className="speed-label">{t('speed')}:{' '}</span>
        <Slider
          min={playbackRates[0]}
          value={selectedValue}
          max={playbackRates[playbackRates.length - 1]}
          marks={sliderMarks}
          step={null}
          onChange={handleOnSliderChange}
        />
      </div>
    </div>
  );
};

export default PlayRateSlider;
