import React from 'react';

const PlayRateMarker = ({ value, active }) => {
  const className = active ? 'marker-active' : 'marker-inactive';

  return (
    <span className={className}>{value}</span>
  );
};

export default PlayRateMarker;
