import React from 'react';

const CourseSummaryOverlay = ({ course, handleOnClick }) => (
  <div id="start_video_overlay" className="video-overlay-poster">
    <h1>{course.name}</h1>
    <h2>
      {course.lessonCount}
      {' '}
      lessons,
      {' '}
      {course.exerciseCount}
      {' '}
      exercises
      {course.hasExam ? ', 1 exam' : ''}
    </h2>
    <a className=" button large green" onClick={handleOnClick}>
      {
        course.isAccessible ? 'Start Course' : 'Preview Course'
      }
    </a>
  </div>
);

export default CourseSummaryOverlay;