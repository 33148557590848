import React from 'react';
import { useTranslation } from "react-i18next";

const VisitorPreviewCTA = ({ ctaUrl, subject }) => {
  const { t } = useTranslation('VisitorPreviewCTA');

  return (
    <div className="post-video-cta">
      <h1 className="marketing-title">{t('LearnWithKubicle', { subject })}</h1>
      <p className="certs-awarded">{t('CertificatesAwarded')}</p>

      <div className="details">
        <ul>
          <li>{t('AccessLibrary')}</li>
          <li>{t('GloballyRecognized')}</li>
          <li>{t('EarnCredits')}</li>
        </ul>
      </div>

      <b>{t('FreeTrial')}</b>
      <p>{t('CancelAnytime')}</p>
      <div className="cta-links">
        <a href="/select-user-type" className="button white large" id="video_overlay_try_for_free">{t('TryForFree')}</a>
        <a href={ctaUrl} className="button dark-blue large" id="video_overlay_learn_more">{t('LearnMore')}</a>
      </div>
    </div>
  );
};
export default VisitorPreviewCTA;
