import React from 'react';

const PreviewStartOverlay = ({ handleOnClick }) => (
  <div id="start_video_overlay" className="video-overlay-poster">
    <a onClick={handleOnClick}>
      <h1>
        <i className="fa fa-play-circle" />
        {' '}
        Preview Lesson
      </h1>
    </a>
  </div>
);

export default PreviewStartOverlay;