import React from 'react';
import VisitorPreviewCTA from "../Common/Components/VisitorPreviewCTA";

const PosterContainer = ({ showNextActionPoster, children }) => (
  <div
    id="poster"
    className="video-overlay-poster post-overlay"
    style={{ display: showNextActionPoster ? 'flex' : 'none' }}
  >
    <div className="post-video-cta">
      {children}
    </div>
  </div>
);

const NextLessonPoster = ({ showNextActionPoster, nextAction }) => (
  <PosterContainer {...{ showNextActionPoster }}>
    <h1 className="hide-for-small">
      Next Lesson:
      {nextAction.title}
    </h1>
    <a href={nextAction.url} className="button large green">Watch next lesson</a>
  </PosterContainer>
);

const NextExercisePoster = ({ showNextActionPoster, nextAction }) => (
  <PosterContainer {...{ showNextActionPoster }}>
    <h1 className="hide-for-small">
      Next Exercise:
      {nextAction.title}
    </h1>
    <a href={nextAction.url} className="button large green">Start Next Exercise</a>
  </PosterContainer>
);

const TakeExamPoster = ({ showNextActionPoster, nextAction }) => (
  <PosterContainer {...{ showNextActionPoster }}>
    <a href={nextAction.url} className="button large green">Take Exam</a>
  </PosterContainer>
);

const ReturnToLibraryPoster = ({ showNextActionPoster, nextAction }) => (
  <PosterContainer {...{ showNextActionPoster }}>
    <a href={nextAction.url} className="button large green">Return to library</a>
  </PosterContainer>
);

const ContactAdminPoster = ({ showNextActionPoster, nextAction }) => (
  <PosterContainer {...{ showNextActionPoster }}>
    <h1>Contact your Kubicle Administrator to request access to this course.</h1>
    <a href={nextAction.url} className="button large green">
      Return to Library
    </a>
  </PosterContainer>
);

const SignUpPoster = ({ showNextActionPoster, nextAction, subject }) => (
  <PosterContainer {...{ showNextActionPoster }}>
    <VisitorPreviewCTA ctaUrl={nextAction.url} subject={subject} />
  </PosterContainer>
);

const NextActionPoster = (props) => {
  const { nextAction } = props;
  const { action } = nextAction;

  switch (action) {
    case 'contact_admin':
      return <ContactAdminPoster {...props} />;
    case 'sign_up':
      return <SignUpPoster {...props} />;
    case 'lesson':
      return <NextLessonPoster {...props} />;
    case 'exercise':
      return <NextExercisePoster {...props} />;
    case 'sql_exercise':
      return <NextExercisePoster {...props} />;
    case 'exam':
      return <TakeExamPoster {...props} />;
    default:
      return <ReturnToLibraryPoster {...props} />;
  }
};

export default NextActionPoster;
