const codes = {
  spaceBar: 32,
  leftArrow: 37,
  upArrow: 38,
  rightArrow: 39,
  downArrow: 40,
  fKey: 70,
  mKey: 77,
};

export default codes;
